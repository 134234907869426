var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "dashboard-component dashboard-theme-" + _vm.theme },
    [
      _c(
        "a-layout",
        [
          _c(
            "a-layout",
            [
              _c(
                "a-layout-header",
                { staticClass: "dashboard-header" },
                [
                  _c(
                    "a-row",
                    [
                      _c("a-col", { attrs: { span: 10 } }, [
                        _vm.loading
                          ? _c(
                              "div",
                              { staticClass: "header-logo" },
                              [
                                _c("img", {
                                  staticStyle: {
                                    height: "24px",
                                    margin: "-10px 8px 0 0",
                                  },
                                  attrs: { src: _vm.Logo },
                                }),
                                _c("a-divider", {
                                  attrs: { type: "vertical" },
                                }),
                                _c("span", { staticClass: "header-title" }, [
                                  _vm._v(_vm._s(_vm.CorpName)),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c("a-col", { attrs: { span: 14 } }, [
                        _c(
                          "div",
                          { staticClass: "username-menu text-right" },
                          [
                            !_vm.IsSuperAdmin
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "right-inline-block" },
                                    [
                                      _c(
                                        "a-popover",
                                        { attrs: { placement: "bottom" } },
                                        [
                                          _c(
                                            "a-badge",
                                            {
                                              attrs: {
                                                count: _vm.unReadNum,
                                                offset: [5, -5],
                                              },
                                            },
                                            [
                                              _c("a-icon", {
                                                attrs: { type: "bell" },
                                                on: {
                                                  click: _vm.navToMessageCenter,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "template",
                                            { slot: "content" },
                                            [
                                              _c(
                                                "a-list",
                                                {
                                                  staticClass:
                                                    "header-alert-overlay",
                                                  attrs: {
                                                    size: "small",
                                                    "data-source":
                                                      _vm.newestReadList,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "renderItem",
                                                        fn: function (
                                                          item,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "a-list-item",
                                                            {
                                                              staticClass:
                                                                "header-alert-overlay-list",
                                                              style: {
                                                                color:
                                                                  item.readStatus ===
                                                                  0
                                                                    ? "#000"
                                                                    : "#ccc",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.openMessage(
                                                                      index,
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "alert-item-wrapper",
                                                                },
                                                                [
                                                                  _c(
                                                                    "a-row",
                                                                    {
                                                                      staticClass:
                                                                        "alert-row-wrapper",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "a-col",
                                                                        {
                                                                          staticClass:
                                                                            "text-ellipsis",
                                                                          attrs:
                                                                            {
                                                                              span: 16,
                                                                              title:
                                                                                item.appName,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.appName
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "a-col",
                                                                        {
                                                                          staticClass:
                                                                            "text-right text-ellipsis",
                                                                          attrs:
                                                                            {
                                                                              span: 8,
                                                                              title:
                                                                                item.sendTime,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.sendTimeDate
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-ellipsis",
                                                                      attrs: {
                                                                        title:
                                                                          item.title,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.title
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "item-body-content item-body-font",
                                                                      attrs: {
                                                                        title:
                                                                          item.content,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.content
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          )
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    3988501266
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      attrs: { slot: "footer" },
                                                      slot: "footer",
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          on: {
                                                            click:
                                                              _vm.getReadAllNews,
                                                          },
                                                        },
                                                        [_vm._v("全部已读")]
                                                      ),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "pull-right",
                                                        },
                                                        [
                                                          _c(
                                                            "router-link",
                                                            {
                                                              attrs: {
                                                                to: "/message/message-center",
                                                              },
                                                            },
                                                            [_vm._v("更多消息")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "header-project-wrapper" },
                                    [
                                      _c(
                                        "a-dropdown",
                                        { attrs: { trigger: ["click"] } },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "ant-dropdown-link drop-menu-text text-ellipsis",
                                              on: {
                                                click: function (e) {
                                                  return e.preventDefault()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.CurrentSelectProjectName ||
                                                      "请选择项目"
                                                  ) +
                                                  " "
                                              ),
                                              _c("a-icon", {
                                                attrs: { type: "down" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.projectList &&
                                          _vm.projectList.length > 0
                                            ? [
                                                _c(
                                                  "a-menu",
                                                  {
                                                    attrs: { slot: "overlay" },
                                                    slot: "overlay",
                                                  },
                                                  _vm._l(
                                                    _vm.projectList,
                                                    function (item) {
                                                      return _c(
                                                        "a-menu-item",
                                                        {
                                                          key: item.id,
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.selectProjectClick(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.name
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ]
                                            : [
                                                _c("a-empty", {
                                                  staticClass: "text-center",
                                                  staticStyle: {
                                                    padding: "16px 32px",
                                                  },
                                                  attrs: {
                                                    slot: "overlay",
                                                    image: _vm.EmptySimpleImage,
                                                    description: "暂无项目",
                                                  },
                                                  slot: "overlay",
                                                }),
                                              ],
                                        ],
                                        2
                                      ),
                                      !_vm.IsCorpAdmin
                                        ? _c(
                                            "a",
                                            {
                                              staticClass: "backto-corp-a",
                                              on: {
                                                click: _vm.backToCorpClick,
                                              },
                                            },
                                            [_vm._v("返回企业")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _c("jtl-icon", {
                              staticClass: "user-header",
                              attrs: { type: "person" },
                            }),
                            _c(
                              "a-dropdown",
                              { attrs: { trigger: ["click"] } },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "ant-dropdown-link drop-menu-text text-ellipsis",
                                    attrs: { title: _vm.UserName },
                                    on: {
                                      click: function (e) {
                                        return e.preventDefault()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" " + _vm._s(_vm.UserName) + " "),
                                    _c("a-icon", { attrs: { type: "down" } }),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-menu",
                                  {
                                    attrs: { slot: "overlay" },
                                    slot: "overlay",
                                  },
                                  [
                                    _c(
                                      "a-menu-item",
                                      { key: "0" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: "/dashboard/person-center",
                                            },
                                          },
                                          [_vm._v("个人中心")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("a-menu-divider"),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "1",
                                        on: { click: _vm.openSystemVersion },
                                      },
                                      [_vm._v("系统信息")]
                                    ),
                                    _c("a-menu-divider"),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "2",
                                        on: { click: _vm.loginOutClick },
                                      },
                                      [_vm._v("退出")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-layout",
                { staticClass: "dashboard-content" },
                [
                  _c(
                    "a-layout-sider",
                    {
                      staticClass: "dashboard-sider",
                      attrs: { trigger: null, collapsible: "" },
                      model: {
                        value: _vm.collapsed,
                        callback: function ($$v) {
                          _vm.collapsed = $$v
                        },
                        expression: "collapsed",
                      },
                    },
                    [
                      _c(
                        "a-menu",
                        {
                          attrs: {
                            theme: _vm.theme,
                            mode: "inline",
                            "default-open-keys": _vm.DefaultOpenMenuKey,
                            "default-selected-keys": [_vm.$route.path],
                            "selected-keys": [_vm.$route.path],
                          },
                          on: {
                            "update:defaultOpenKeys": function ($event) {
                              _vm.DefaultOpenMenuKey = $event
                            },
                            "update:default-open-keys": function ($event) {
                              _vm.DefaultOpenMenuKey = $event
                            },
                          },
                        },
                        [
                          _vm._l(_vm.MenuList, function (menu) {
                            return [
                              !menu.subMenus || menu.subMenus.length === 0
                                ? _c(
                                    "a-menu-item",
                                    {
                                      key: menu.route,
                                      class: [
                                        "ant-menu-item-wrapper",
                                        {
                                          "ant-menu-item-selected ant-menu-sub-item-selected":
                                            _vm.isMenuSelected(menu.route),
                                        },
                                      ],
                                      on: {
                                        click: function ($event) {
                                          return _vm.menuClick(menu)
                                        },
                                      },
                                    },
                                    [
                                      _c("jtl-icon", {
                                        attrs: { type: menu.icon },
                                      }),
                                      _c("span", [_vm._v(_vm._s(menu.name))]),
                                    ],
                                    1
                                  )
                                : _c("sub-menu", {
                                    key: menu.route,
                                    attrs: {
                                      "menu-info": menu,
                                      "menu-click": _vm.menuClick,
                                      "is-menu-selected": _vm.isMenuSelected,
                                    },
                                  }),
                            ]
                          }),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "collapsed-wrapper text-center",
                          on: { click: _vm.menuCollapseClick },
                        },
                        [_c("a-icon", { attrs: { type: _vm.CollapsedIcon } })],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-layout-content",
                    { staticClass: "dashboard-view" },
                    [_c("router-view", { staticClass: "dashboard-content" })],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "系统信息",
            width: "600px",
            footer: null,
            visible: _vm.dialogVisible,
          },
          on: { cancel: _vm.openSystemVersion },
        },
        [_vm._v(" 系统版本: " + _vm._s(_vm.Version) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }