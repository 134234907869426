import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import store from '@common-src/store';
import { UserModel } from '@common-src/model/user-model';
// import { initSentryUserInfo } from '@common-src/sentry';
import { ProjectEntityModel } from '@common-src/entity-model/project-entity';
import { PermissionType, ResourceType } from '@common-src/model/enum';
import { ModuleType } from '@common-src/enum/module-type.enum';

/**
 * 获取环境变量里配置的值
 * 如果是相对地址，则加上/
 * @param logoName 名称
 * @param defaultValue 默认值
 * @returns
 */
function getEnvValue(name: string, defaultValue?: string) {
    const envLogo = process.env[name];
    if (!envLogo) {
        return defaultValue;
    }
    if (envLogo.indexOf('http://') > -1) {
        return envLogo;
    }
    return `/${envLogo}`;
}

// localStorage常量key
const JTL_USERINFO_LOCAL = 'jtl_userinfo';
const JTL_REDIRECT_PATH_LOCAL = 'jtl_redirect_path';
export const JTL_TOKEN_LOCAL = 'jtl_token';
const JTL_REFRESH_TOKEN_LOCAL = 'jtl_refresh_token';
export const JTL_CURRENT_PROJECT = 'jtl_current_project';
const EVERYONE_MENUS = ['/dashboard/person-center'];
const CORP_MENUS = [];
const PROJECT_MENUS = [];
const LOGOIMAGE = 'https://jdy-rd-dev.oss-cn-hangzhou.aliyuncs.com/workflow/65165826-891e-4481-aeae-ccc5a2aa5f7a/金朵云logo左右结构-白色.png';
const LOGOICONIMAGE = 'https://jdy-rd-dev.oss-cn-hangzhou.aliyuncs.com/workflow/bf4d5748-e62d-41f5-b501-0915ade344f1/16x16.png';
const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCN9Oy2wwdwfW1Q9SkXAsd625aJ2SBvvWhb95bytc6OEuJMFjGNa8gxvbE9v7fnTGOaiiL2o3Avoyf3HdenTb/1xf4YSbJAPu43TsLxzdTXk0TncXNzyZ+c8boaGYEMt+mRQJWUxdxVcDGkcVwqruI1U3C6fEGnbu5+nbSUoTtrDQIDAQAB';
/**
 * 从localStorage获取jtl_userinfo
 */
function getUserInfoFromLocal() {
    let userInfoFromLocal: UserModel;

    try {
        const localData = JSON.parse(localStorage.getItem(JTL_USERINFO_LOCAL));
        localData.token = localStorage.getItem(JTL_TOKEN_LOCAL);
        localData.refreshToken = localStorage.getItem(JTL_REFRESH_TOKEN_LOCAL);
        userInfoFromLocal = new UserModel(localData);
    } catch (e) {
        const token = localStorage.getItem(JTL_TOKEN_LOCAL);
        userInfoFromLocal = new UserModel();
        userInfoFromLocal.token = token;
        $log.warn('缓存用户数据为空或格式不正确', localStorage.getItem(JTL_USERINFO_LOCAL));
    }

    // initSentryUserInfo(userInfoFromLocal);
    return userInfoFromLocal;
}

function getProjectFromLocal() {
    let localData: any;
    try {
        localData = JSON.parse(localStorage.getItem(JTL_CURRENT_PROJECT));
    } catch (e) {
        console.warn(e);
    }
    return localData;
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule {
    private userInfo: UserModel = null;
    private userExtraInfo: UserModel = null;
    private userPermissions: Array<any> = null;
    private hasError: boolean = false;
    private currentProject: ProjectEntityModel = null;
    // 未读消息数量
    public unReadMessagesNum:number = 0;
    // logo 地址
    public LogUrl: string = LOGOIMAGE;
    // 平台名称
    public PlatformName: string = null;

    public InitName: string = null;

    public publicKey: string = publicKey;

    constructor() {
        super({});
        this.userInfo = getUserInfoFromLocal();

        const projectLocal = getProjectFromLocal();
        let prefixTitle = '';
        if (JTL.moduleType === ModuleType.EDGE) {
            prefixTitle = '边缘网关';
        } else if (JTL.moduleType === ModuleType.WORKORDER) {
            prefixTitle = '';
        }
        if (projectLocal) {
            this.currentProject = new ProjectEntityModel().toModel(projectLocal);
        }
        const corpName = _.get(this.userExtraInfo, 'corpName') || _.get(this.userInfo, 'corpName');
        if (corpName) {
            this.InitName = `${corpName}${prefixTitle}`;
        } else {
            this.InitName = `${JTL?.CONFIG?.corpName || process.env.VUE_APP_CORP_NAME}${prefixTitle}`;
        }
        this.PlatformName = this.InitName;
    }
    // 更新未读消息数量
    @Mutation
    public SET_UN_READ_MESSAGE_NUM(num:number): void {
        this.unReadMessagesNum = num;
    }

    // 更新LOGO_URL
    @Mutation
    public SET_LOGO_URL(url?: string): void {
        this.LogUrl = url;
    }

    // 更新PlatformName
    @Mutation
    public SET_PLATFORM_NAME(name?: string): void {
        this.PlatformName = name;
    }

    @Mutation
    public SET_USERINFO(userinfo?: UserModel): void {
        this.userInfo = userinfo;

        if (userinfo && !userinfo.IsEmpty) {
            localStorage.setItem(JTL_TOKEN_LOCAL, userinfo.token);
            localStorage.setItem(JTL_REFRESH_TOKEN_LOCAL, userinfo.refreshToken);
        } else {
            localStorage.removeItem(JTL_TOKEN_LOCAL);
            localStorage.removeItem(JTL_REFRESH_TOKEN_LOCAL);
        }
    }

    @Mutation
    public SET_USER_EXTRA_INFO(userInfo: any): void {
        if (userInfo) {
            this.userExtraInfo = new UserModel(userInfo);
            localStorage.setItem(JTL_USERINFO_LOCAL, JSON.stringify(this.userExtraInfo.getLocalData()));
            // initSentryUserInfo(this.userExtraInfo);
            if (this.userExtraInfo.IsSuperAdmin) {
                this.currentProject = null;
            }
        } else {
            localStorage.removeItem(JTL_USERINFO_LOCAL);
            // initSentryUserInfo();
        }

        // init the favicon
        const link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        // link.href = this.Logo;
        // link.href = _.get(this.currentProject, 'logo') || _.get(this.userExtraInfo, 'logo') || _.get(JTL, 'CONFIG.logo') || getEnvValue('VUE_APP_LOGO', '/favicon.ico');
        link.href = localStorage.getItem('title_logo') || _.get(JTL, 'CONFIG.logoIcon') || getEnvValue('VUE_APP_LOGO_ICON') || _.get(this.currentProject, 'logo') || _.get(this.userExtraInfo, 'logo') || _.get(JTL, 'CONFIG.logo') || getEnvValue('VUE_APP_LOGO', '/favicon.ico');
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    @Mutation
    public SET_REDIRECT_PATH(redirectPath?: string): void {
        if (redirectPath) {
            localStorage.setItem(JTL_REDIRECT_PATH_LOCAL, redirectPath);
        } else {
            localStorage.removeItem(JTL_REDIRECT_PATH_LOCAL);
        }
    }

    @Mutation
    public SET_USER_PERMISSION(permissions: Array<string>): void {
        this.userPermissions = permissions;
        // 页面权限配置层级不够 显示不出来当前权限，现在内置进来，待下个版本产品优化
        _.forEach(this.userPermissions, r => {
            if (r.type === PermissionType.PROJECT) {
                r.permissions.push({
                    category: 'PAGE',
                    identifier: 'dashboardData:messageManage:/message/message-center:/message/message-alarm-detail/*',
                    name: '消息告警详情'
                });
            }
        });
    }

    @Mutation
    public SET_CURRENT_PROJECT(project: ProjectEntityModel): void {
        this.currentProject = project;
        if (project) {
            localStorage.setItem(JTL_CURRENT_PROJECT, JSON.stringify(project.toService()));
        } else {
            localStorage.removeItem(JTL_CURRENT_PROJECT);
        }
    }

    @Mutation
    public SET_HAS_ERROR(hasError): void {
        this.hasError = hasError;
    }

    /**
     * 获取用户权限路由
     */
    get UserPermissions(): Array<string> {
        const currentPermissions = _.map(_.get(_.find(this.userPermissions, item => (item.type === this.CurrentUserPermissionType)), 'permissions'), item => item.identifier) || [];
        if (this.CurrentUserPermissionType === PermissionType.CORP) {
            return _.union(currentPermissions, EVERYONE_MENUS, CORP_MENUS);
        }
        return _.union(currentPermissions, EVERYONE_MENUS, PROJECT_MENUS);
    }

    /**
     * 获取用户权限菜单
     */
    get UserPermissionList(): Array<any> {
        const arr = _.filter(_.get(_.find(this.userPermissions, item => item.type === this.CurrentUserPermissionType), 'permissions'), item => item.category !== ResourceType.FUNCTION);
        const maxLevel = _.max(arr.map(item => item.identifier.split(':').length));
        let menuList = [];
        for (let i = 0; i < maxLevel; i++) {
            menuList.push(..._.filter(arr, item => item.identifier.split(':').length - 1 === i));
        }
        return _.union(_.filter(menuList, item => !_.includes(['/message/message-center', '/message/message-alarm-detail/*'], _.last(item.identifier.split(':')))));
    }

    /**
     *
     * 获取用户权限类型
     */
    get CurrentUserPermissionType(): PermissionType {
        if (_.get(this.userExtraInfo, 'IsSuperAdmin')) {
            return PermissionType.PLATFORM;
        }
        return this.CurrentProjectId ? PermissionType.PROJECT : PermissionType.CORP;
    }
    /**
     * 获取用户权限,用于edge_computing 不区分项目id的接口结构
     */
    get UserPermissionsEdge(): Array<string> {
        const currentPermissions = this.userPermissions;
        return _.union(EVERYONE_MENUS, currentPermissions);
    }

    /**
     * 获取默认路由
     */
    get DefaultRoute(): string {
        let defaultRoute = null;
        if (JTL.moduleType === ModuleType.EDGE) {
            return '/dashboard/index';
        }
        if (JTL.moduleType === ModuleType.WORKORDER) {
            return '/dashboard/service-center/all';
        }
        const currentPermissions = _.map(_.filter(_.get(_.find(this.userPermissions, item =>
            (item.type === this.CurrentUserPermissionType)), 'permissions'), item =>
            item.category !== ResourceType.FUNCTION), item => item.identifier) || [];
        const childrenMenu = _.union(currentPermissions, EVERYONE_MENUS).filter(item => item.indexOf('/') > -1);
        switch (this.CurrentUserPermissionType) {
            case PermissionType.PLATFORM:
                defaultRoute = '/admin/corp-list';
                break;
            default:
            case PermissionType.CORP:
            case PermissionType.PROJECT:
                defaultRoute = childrenMenu.includes('/dashboard/index') ? '/dashboard/index' : _.last(_.split(_.get(childrenMenu, '[0]'), ':'));
                break;
        }
        return defaultRoute;
    }

    /**
     * 获取登录用户的token
     */
    get Token() {
        return _.get(this.userInfo, 'token');
    }
    /**
     * 获取登录用户信息
     */
    get UserInfo() {
        return this.userExtraInfo;
    }

    /**
     * 是否是超级管理员
     */
    get IsSuperAdmin() {
        // return true;
        return _.get(this.userInfo, 'IsSuperAdmin') || _.get(this.userExtraInfo, 'IsSuperAdmin');
    }

    /**
     * 是否是平台用户
     */
    // get IsAdmin() {
    //     return true;
    // }

    /**
     * 获取登录用户的RefreshToken
     */
    get RefreshToken() {
        return _.get(this.userInfo, 'refreshToken');
    }

    /**
     * 获取用户登陆状态
     */
    get IsLogin(): boolean {
        return !!this.Token;
    }

    get HasError(): boolean {
        return this.hasError;
    }

    /**
     * 获取登陆后的跳转路径
     */
    get RedirectPath(): string {
        return localStorage.getItem(JTL_REDIRECT_PATH_LOCAL) || this.DefaultRoute;
    }

    /**
     * 获取登录用户的用户名
     */
    get UserName(): string {
        return _.get(this.userExtraInfo, 'username') || _.get(this.userExtraInfo, 'email');
    }

    get CorpName(): string {
        return this.InitName;
    }

    get Logo(): string {
        return _.get(this.currentProject, 'logo') || _.get(this.userExtraInfo, 'logo') || _.get(JTL, 'CONFIG.logo') || getEnvValue('VUE_APP_LOGO', '/favicon.ico');
    }

    get LoginImage(): string {
        return _.get(this.currentProject, 'loginBg') || _.get(JTL, 'CONFIG.webLoginBg') || getEnvValue('VUE_APP_WEB_LOGIN_BG', '/img/login-bg.jpg');
    }

    get LogoIcon(): string {
        return _.get(JTL, 'CONFIG.logoIcon') || getEnvValue('VUE_APP_LOGO_ICON') || this.Logo;
    }

    /**
     * 获取登录用户的ID
     */
    get UserId(): string {
        return _.get(this.userExtraInfo, 'id');
    }

    /**
     * 获取当前选择的项目
     */
    get CurrentProject(): ProjectEntityModel {
        return this.currentProject;
    }

    /**
     * 获取当前选择项目的ID
     */
    get CurrentProjectId(): string {
        if (JTL.moduleType === ModuleType.EDGE) {
            return undefined;
        }
        return _.get(this.currentProject, 'id');
    }

    @Action
    rsyncUserInfo() {
        const localToken = localStorage.getItem(JTL_TOKEN_LOCAL);

        if (this.userInfo.token && localToken !== this.userInfo.token) {
            this.SET_USERINFO(getUserInfoFromLocal());
            if (!this.IsLogin) {
                window.location.reload();
            }
        }
        // let userInfoFromLocal: UserModel = getUserInfoFromLocal();
        // if (!(JSON.stringify(userInfoFromLocal) === JSON.stringify(this.userInfo))) {
        //     this.SET_USERINFO(userInfoFromLocal);
        //     if (!this.IsLogin) {
        //         window.location.reload();
        //     }
        // }
    }
}

export const UserStoreModule = getModule(User);
