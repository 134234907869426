import { ServiceCenterEntityModel, ServiceCenterQueryModel } from '@common-src/entity-model/service-center-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, del, put } from './request';
import CommonService from '@common-src/service/common';
import SpaceV2Service from '@common-src/service/space-v2';
import { MOBILE_REGEXP } from '@common-src/model/regexp';
import { UserStoreModule } from '@common-src/store/modules/user';
import { PropertyEntityType } from '@common-src/model/enum';
import moment from 'moment';

const URL_PATH = WORKORDER_BASE_REQUEST_PATH;

export default class ServiceCenterService implements ICRUDQ<ServiceCenterEntityModel, ServiceCenterQueryModel> {
    private type: any;

    // 全部 vs 个人
    private ifPersonal:boolean = true;

    constructor(ifPersonal?:any) {
        this.ifPersonal = ifPersonal;
    }

    async create(model: ServiceCenterEntityModel):Promise<ServiceCenterEntityModel> {
        const url = `${URL_PATH}/${this.type}`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async update(model: ServiceCenterEntityModel):Promise<ServiceCenterEntityModel> {
        const url = `${URL_PATH}/${this.type}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: ServiceCenterEntityModel):Promise<ServiceCenterEntityModel> {
        const url = `${URL_PATH}/servicedesk/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: ServiceCenterQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${ASSET_BASE_REQUEST_PATH}/workorder/list`;
        // 获取全部服务请求
        let params = Object.assign({ page, limit }, query.toService());
        // // 获取个人服务请求
        // if (this.ifPersonal) {
        //     let userId = JSON.parse(localStorage.getItem('jtl_userinfo')).id;
        //     params = Object.assign({ page, limit }, { assignee: userId }, query?.toService());
        // }
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new ServiceCenterEntityModel(this.type).toModel(item));
        return res;
    }

    // 保存评价结果
    async saveEvaluation(params:any):Promise<any> {
        const url = params.id ? `${URL_PATH}/evaluation/update` : `${URL_PATH}/evaluation/add`;
        const res = await post(url, params);
        return res;
    }

    // 获取流程图信息
    async getFlowSVG(processInstanceId:string):Promise<any> {
        const url = `${URL_PATH}/process/image/${processInstanceId}`;
        const res = await get(url);
        return res;
    }

    // 获取初始表单信息
    async getStartProperties(woConfigId:string):Promise<any> {
        let url = `${URL_PATH}/workorder-type/process-definition-relation/${woConfigId}/startform`;
        const res = await get(url);

        let actions = _.map(res, item => {
            let fields = _.map(_.filter(_.get(item, 'fields'), field => !field.readonly && field.visible), field => {
                let PropertyRes = this.initFormProperty(field, true);
                // 申请人与申请人电话默认填写为当前账户信息
                if (PropertyRes.key === 'applicant') {
                    PropertyRes.value = UserStoreModule.UserInfo.username;
                } else if (PropertyRes.key === 'applicantContact') {
                    PropertyRes.value = UserStoreModule.UserInfo.phone;
                } else if (PropertyRes.key === 'creator') {
                    PropertyRes.value = UserStoreModule.UserInfo.username;
                } else if (PropertyRes.key === 'creatorContact') {
                    PropertyRes.value = UserStoreModule.UserInfo.phone;
                } else if (PropertyRes.key === 'maintenancePeople') {
                    if (!PropertyRes.value) {
                        PropertyRes.value = [UserStoreModule.UserId];
                    }
                }

                return PropertyRes;
            });

            let additionalFields = _.map(_.filter(_.get(item, 'additionalFields'), field => !field.readonly && field.visible), field => {
                return this.initAdditionProperty(field);
            });

            let assignment = this.initAssignment(item.assignment);

            _.map(assignment.formControls, item => {
                additionalFields.unshift(item);
            });

            return {
                actionFlowKey: item.actionFlowKey,
                actionFlowName: item.actionFlowName,
                fields: fields,
                additionalFields: additionalFields,
                assignment: assignment
            };
        });

        let result = {
            nextFlows: actions,
            logData: []
        };
        return result;
    }

    // 保存新创建的工单
    async saveFirstForm(actionFlowKey:string, formModel: any, additionModel:any, woConfigId:string, alarmId: string = ''):Promise<boolean> {
        let params: any = {
            actionFlowKey: actionFlowKey,
            formProperties: formModel,
            additionProperties: {},
            candidateGroup: '',
            assignee: ''
        };
        let url = `${URL_PATH}/process/${woConfigId}/start`;
        if (alarmId) {
            url = `${ALARM_CENTER_BASE_REQUEST_PATH}/device/alarm/${woConfigId}/start`;
            params.formProperties.alarm_id = alarmId;
        }

        if (additionModel.candidateGroup) {
            params.candidateGroup = additionModel.candidateGroup;
            delete additionModel.candidateGroup;
        }

        if (additionModel.assignee) {
            params.assignee = additionModel.assignee;
            delete additionModel.assignee;
        }

        if (!params.assignee) {
            delete params.assignee;
        }

        params.additionProperties = additionModel;
        const res = await post(url, params);
        return res;
    }

    // 获取工单详情（查看）
    async getWorkOrderProperties(woId: string):Promise<any> {
        let url = `${URL_PATH}/workorders/details/${woId}`;
        const res = await get(url);

        let fields = _.map(res.formFields, field => {
            field.readonly = true;
            field.visible = true;
            return this.initFormProperty(field, true);
        });
        let additionalFields = [];
        let assignment = {};
        let logData = res.processInstanceInfo.executionInfos;

        if (logData && logData.length > 0) {
            logData.map(item => {
                if (item.taskStayedTime) {
                    this.timeFilter(item.taskStayedTime, item);
                }
            });
        }

        if (res.processInstanceInfo.evaluation) {
            let value = res.processInstanceInfo.evaluation;
            if (value.orderId) {
                let rateLog = {
                    userDisplayName: value.evaluationPerson,
                    createdDT: value.createDate,
                    flowElementId: '',
                    name: '评价',
                    taskNodeName: '评价',
                    serviceAttitude: parseInt(value.serviceAttitude),
                    responseSpeed: parseInt(value.responseSpeed),
                    serviceQuality: parseInt(value.serviceQuality),
                    content: value.content
                };
                logData.push(rateLog);
            }
        }

        let result = {
            processInstanceId: res.processInstanceId,
            currentProcess: [{
                actionFlowKey: res.flowStatus,
                actionFlowName: res.flowStatusName,
                fields: fields,
                additionalFields: additionalFields,
                assignment: assignment,
                logData: logData
            }],
            logData: logData,
            orderStatus: res.orderStatus || '',
            creator: res.processInstanceInfo.creator || '',
            flowStatus: res.flowStatus
        };

        return result;
    }

    // 获取工单详情（编辑）
    async getWorkOrderDetails(woId: string):Promise<any> {
        let url = `${URL_PATH}/usertask/details/${woId}`;
        const res = await get(url);
        let actions = _.map(res.nextActionForms, item => {
            let fields = _.map(_.filter(_.get(item, 'fields'), field => field.visible), field => {
                let PropertyRes = this.initFormProperty(field, true);
                if (PropertyRes.key === 'maintenancePeople') {
                    if (!PropertyRes.value) {
                        PropertyRes.value = [UserStoreModule.UserId];
                    }
                }
                return PropertyRes;
            });

            let additionalFields = _.map(_.filter(_.get(item, 'additionalFields'), field => !field.readonly && field.visible), field => {
                return this.initAdditionProperty(field);
            });

            let assignment = this.initAssignment(item.assignment);

            _.map(assignment.formControls, item => {
                additionalFields.unshift(item);
            });

            return {
                actionFlowKey: item.actionFlowKey,
                actionFlowName: item.actionFlowName,
                fields: fields,
                additionalFields: additionalFields,
                assignment: assignment
            };
        });

        let logData = res.workOrderSubject.processInstanceInfo.executionInfos;

        if (logData && logData.length > 0) {
            logData.map(item => {
                if (item.taskStayedTime) {
                    this.timeFilter(item.taskStayedTime, item);
                }
            });
        }

        if (res.workOrderSubject.processInstanceInfo.executionInfos.evaluation) {
            let value = res.workOrderSubject.processInstanceInfo.executionInfos.evaluation;

            if (value.orderId) {
                let rateLog = {
                    userDisplayName: value.evaluationPerson,
                    createdDT: value.createDate,
                    flowElementId: '',
                    name: '评价',
                    serviceAttitude: parseInt(value.serviceAttitude),
                    responseSpeed: parseInt(value.responseSpeed),
                    serviceQuality: parseInt(value.serviceQuality),
                    content: value.content
                };
                logData.push(rateLog);
            }
        }

        let result = {
            processInstanceId: res.workOrderSubject.processInstanceInfo.id,
            nextFlows: actions,
            logData: logData,
            orderStatus: res.workOrderSubject.orderStatus || '',
            creator: res.workOrderSubject.processInstanceInfo.creator || '',
            flowStatus: res.workOrderSubject.flowStatus
        };
        return result;
    }

    /* 保存已创建的工单 */
    async saveForm(actionFlowKey:string, formModel: any, additionModel:any, woId:string):Promise<boolean> {
        let params: any = {
            actionFlowKey: actionFlowKey,
            formProperties: formModel,
            additionProperties: {},
            candidateGroup: '',
            assignee: ''
        };
        if (params.formProperties.planTime) {
            params.formProperties.planTime = moment(params.formProperties.planTime).format('YYYY-MM-DD HH:mm:ss');
        }

        if (additionModel.candidateGroup) {
            params.candidateGroup = additionModel.candidateGroup;
            delete additionModel.candidateGroup;
        }

        if (additionModel.assignee) {
            params.assignee = additionModel.assignee;
            delete additionModel.assignee;
        }

        params.additionProperties = additionModel;

        let url = `${URL_PATH}/usertask/complete/${woId}`;

        const res = await post(url, params);
        return res;
    }

    // 搁置、取消搁置、终止工单
    async processWorkOrder(woId:string, reason:string, status:string) {
        let url = `${URL_PATH}/usertask/changeStatus`;
        let params = {
            id: woId,
            reason: reason,
            status: status
        };
        const res = await post(url, params);
        return res;
    }

    // 重新分派
    async reAssign(woId:string, taskId:string, userId:string, groupId:string) {
        const url = `${URL_PATH}/usertask/assignee/${woId}`;
        let params = {
            taskId: taskId,
            userId: userId,
            groupId: groupId
        };

        if (!userId) {
            delete params.userId;
        }

        const res = await put(url, params);
        return res;
    }

    // 处理时长
    timeFilter(secdons, item) {
        if (!item.timeFormat) {
            item.timeFormat = '';
        }

        if (secdons < 60) {
            var sec;
            sec = (secdons > 0) ? parseInt(secdons) + '秒' : '';
            item.timeFormat = item.timeFormat + sec;
        } else if (secdons < 3600) {
            var min;
            min = Math.floor(secdons / 60);
            item.timeFormat = item.timeFormat + min + '分钟';
            this.timeFilter(secdons - min * 60, item);
        } else if (secdons < 86400) {
            var hour;
            hour = Math.floor(secdons / 3600);
            item.timeFormat = item.timeFormat + hour + '小时';
            this.timeFilter(secdons - hour * 3600, item);
        } else {
            var day;
            day = Math.floor(secdons / 86400);
            item.timeFormat = item.timeFormat + day + '天';
            this.timeFilter(secdons - day * 86400, item);
        }

        return item.timeFormat;
    }

    private initFormProperty(propertyDto: any, isTwoColumn: boolean = false) {
        const propertyModel: any = {
            key: propertyDto.name,
            value: propertyDto.value,
            label: propertyDto.displayName,
            readonly: propertyDto.readonly,
            required: propertyDto.required,
            type: PropertyTypeMap[propertyDto.dataType],
            // updateHidden: !propertyDto.readable,
            relationKeys: [propertyDto.subSelectId],
            relationPath: propertyDto.subSelectUrl,
            specialType: propertyDto.dataType,
            options: propertyDto.options ? JSON.parse(propertyDto.options) : [],
            layout: undefined
        };

        if (propertyModel.type && propertyModel.type.indexOf('SELECT') >= 1) {
            propertyModel.placeholder = `请选择${propertyDto.displayName}`;
        }

        const tableValue = propertyModel.value;

        switch (propertyDto.dataType) {
            case 'IMAGE':
                propertyModel.layout = { labelCol: 3, wrapperCol: 20, width: '100% ' };
                propertyModel.listType = 'picture-card';
                propertyModel.showUploadList = true;
                propertyModel.accept = '.jpg,.png,.mp4';
                propertyModel.maxFileNumber = 1;
                break;
            case 'IMAGES':
                propertyModel.layout = { labelCol: 3, wrapperCol: 20, width: '100% ' };
                propertyModel.listType = 'picture-card';
                propertyModel.showUploadList = true;
                propertyModel.accept = '.jpg,.png,.mp4';
                propertyModel.maxFileNumber = 9;
                break;
            case 'ATTACHMENT':
                propertyModel.layout = { labelCol: 3, wrapperCol: 20, width: '100% ' };
                propertyModel.listType = 'text';
                propertyModel.showUploadList = true;
                propertyModel.maxFileNumber = 1;
                break;
            case 'ATTACHMENTS':
                propertyModel.layout = { labelCol: 3, wrapperCol: 20, width: '100% ' };
                propertyModel.listType = 'text';
                propertyModel.showUploadList = true;
                propertyModel.maxFileNumber = 9;
                break;
            case 'DROPDOWN':
                if (propertyModel.options) {
                    propertyModel.options = JSON.parse(propertyDto.options);
                }
                break;
            case 'RICHTEXT':
                propertyModel.layout = { labelCol: 3, wrapperCol: 20, width: '100%' };
                propertyModel.rows = 4;
                break;
            case 'SPACE':
                propertyModel.optionsPromise = SpaceV2Service.getLocationTree;
                break;
            case 'PROFESSION':
                propertyModel.optionsPromise = CommonService.getProfessionalTree;
                break;
            case 'DEPARTMENT':
                propertyModel.optionsPromise = CommonService.getOrgs;
                break;
            case 'LEVEL':
                propertyModel.optionsPromise = CommonService.getPropertyOptions;
                propertyModel.optionsPromiseParam = PropertyEntityType.WORK_ORDER_LEVEL_CONF;
                break;
            // 工单设备状态的options不使用数据字典
            // case 'DEVICESTATUS':
            //     propertyModel.optionsPromise = CommonService.getPropertyOptions;
            //     propertyModel.optionsPromiseParam = PropertyEntityType.ASSET_STATUS;
            //     break;
            case 'ASSET':
                propertyModel.optionsPromise = CommonService.getAssetsList;
                break;
            case 'DEVICE':
                propertyModel.optionsPromise = CommonService.getDeviceList;
                break;
            case 'PERSON':
                propertyModel.optionsPromise = CommonService.getPersonList;
                break;
            case 'MULTISELECT':
                propertyModel.placeholder = `请选择${propertyDto.displayName}`;
                propertyModel.mode = 'multiple';
                break;
            case 'MOBILEPHONE':
                propertyModel.pattern = MOBILE_REGEXP;
                break;
            case 'DATE':
                propertyModel.format = 'yyyy-MM-dd HH:mm:ss';
                propertyModel.value = moment(propertyDto.value);
                break;
            case 'TABLE':
                propertyModel.columns = tableValue ? _.map(tableValue.tableColumns, item => {
                    if (item.writeable) {
                        item['scopedSlots'] = { customRender: 'input' };
                    }
                    return item;
                }) : [];
                propertyModel.layout = { labelCol: 3, wrapperCol: 20, width: '100%' };
                // if (_.get(tableValue, 'data[0][0].code')) {
                if (['inspectionRecord', 'checkList'].indexOf(propertyDto.name) > -1) {
                    propertyModel.value = tableValue ? this.generateTableValue(tableValue.data) : [];
                    propertyModel.cellType = tableValue ? this.generateTableType(tableValue.data) : [];
                } else {
                    propertyModel.value = tableValue?.data || [];
                    // propertyModel.cellType = null;
                }
                // propertyModel.value = tableValue ? this.generateTableValue(tableValue.data) : [];
                // propertyModel.cellType = tableValue ? this.generateTableType(tableValue.data) : [];
                propertyModel.pagination = false;
                propertyModel['disabled'] = propertyModel.readonly;
                break;
        }

        if (!propertyModel.layout) {
            switch (propertyDto.name) {
                case 'title':
                    propertyModel.layout = { labelCol: 3, wrapperCol: 20, width: '100%' };
                    break;
                case 'description':
                    propertyModel.layout = { labelCol: 3, wrapperCol: 20, width: '100%' };
                    propertyModel.rows = 4;
                    break;
                case 'maintenancePeople':
                    propertyModel.layout = { labelCol: 6, wrapperCol: 16, width: '50% ' };
                    propertyModel.optionsPromise = CommonService.getPersonList;
                    break;
                default:
                    propertyModel.layout = { labelCol: 6, wrapperCol: 16, width: '50% ' };
                    break;
            }
        }

        if (!isTwoColumn) {
            propertyModel.layout = { labelCol: 5, wrapperCol: 15, width: '100% ' };
        }

        return propertyModel;
    }

    private generateTableValue(value:any) {
        let result = [];
        _.map(value, val => {
            let dataVal = {};
            _.map(val, v => {
                dataVal[v['code']] = v['value'];
            });
            result.push(dataVal);
        });
        return result;
    }

    private generateTableType(value:any) {
        let result = [];
        _.map(value, val => {
            let dataVal = {};
            _.map(val, v => {
                if (v['type'] === 'CHECKBOX') {
                    dataVal[v['code']] = { type: v['type'], visible: v['visible'], name: ['未巡检', '异常', '正常'] };
                } else {
                    dataVal[v['code']] = { type: v['type'], visible: v['visible'] };
                }
            });
            result.push(dataVal);
        });
        return result;
    }

    private initAdditionProperty(propertyDto: any) {
        const propertyModel: any = {
            key: propertyDto.key,
            value: propertyDto.value,
            label: propertyDto.name,
            required: propertyDto.required,
            type: PropertyTypeMap[propertyDto.dataType],
            specialType: propertyDto.dataType,
            layout: undefined
        };

        switch (propertyDto.key) {
            case 'comment':
                propertyModel.type = 'TEXT_AREA';
                propertyModel.rows = 4;
                break;
            case 'commentImages':
                propertyModel.type = 'UPLOAD';
                propertyModel.listType = 'picture-card';
                propertyModel.showUploadList = true;
                propertyModel.accept = '.jpg,.png';
                break;
            default:
                propertyModel.layout = { labelCol: 6, wrapperCol: 16, width: '50% ' };
                break;
        }

        propertyModel.layout = { labelCol: 5, wrapperCol: 15, width: '100% ' };
        return propertyModel;
    }

    private initAssignment(assignment: any) {
        if (!assignment) {
            return {
                assignmentType: '',
                formControls: []
            };
        }

        let assignmentRes = {
            assignmentType: assignment.assignmentType,
            formControls: []
        };

        let candidateGroupControl = {
            key: 'candidateGroup',
            value: '',
            label: '受理组',
            required: true,
            readonly: false,
            type: 'TREE_SELECT',
            layout: { labelCol: 5, wrapperCol: 15, width: '100% ' },
            optionsPromise: CommonService.getOrgs,
            relationKeys: ['assignee'],
            relationPath: 'workorder/org/professional/findByProfessional',
            specialType: 'candidateGroup'
        };

        let assigneeControl = {
            key: 'assignee',
            value: '',
            label: '受理人',
            required: true,
            readonly: false,
            type: 'SELECT',
            layout: { labelCol: 5, wrapperCol: 15, width: '100% ' },
            optionsPromise: CommonService.getOrgUserList,
            // optionsPromiseParam: JSON.parse(assignment.options)['groupId'],
            optionsPromiseParam: 'candidateGroup',
            relationPath: `${WORKORDER_BASE_REQUEST_PATH}/common/assigners/list`,
            specialType: 'assignee'
        };

        switch (assignment.assignmentType) {
            case 'User':
                candidateGroupControl.value = JSON.parse(assignment.options)['groupId'];
                candidateGroupControl.readonly = true;
                assigneeControl.value = JSON.parse(assignment.options)['userId'];
                assigneeControl.readonly = true;
                assignmentRes.formControls.push(assigneeControl);
                assignmentRes.formControls.push(candidateGroupControl);
                break;
            case 'Group':
                candidateGroupControl.value = JSON.parse(assignment.options)['groupId'];
                candidateGroupControl.readonly = true;
                assignmentRes.formControls.push(candidateGroupControl);
                break;
            case 'Profession':
                candidateGroupControl.value = JSON.parse(assignment.options)['groupId'];
                candidateGroupControl.specialType = JSON.parse(assignment.options)['groupId'];
                assigneeControl.value = JSON.parse(assignment.options)['userId'];
                assigneeControl.specialType = JSON.parse(assignment.options)['userId'];
                assigneeControl.required = false;
                assignmentRes.formControls.push(assigneeControl);
                assignmentRes.formControls.push(candidateGroupControl);
                break;
            case 'OtherCondition':
            case 'Role':
                break;
        }

        return assignmentRes;
    }

    async getStatusOptions():Promise<any> {
        const url = `${URL_PATH}/process/definition/processstatus/${this.type}`;
        const res = await get(url);
        return res;
    }
}

const PropertyTypeMap = {
    'NUMBER': 'NUMBER',
    'TEXT': 'TEXT',
    'RICHTEXT': 'TEXT_AREA',
    'DROPDOWN': 'SELECT',
    'LEVEL': 'SELECT',
    'DATE': 'SELECT_DATE',
    'SPACE': 'TREE_SELECT',
    'PROFESSION': 'TREE_SELECT',
    'DEPARTMENT': 'TREE_SELECT',
    'DEVICE': 'SELECT',
    'ASSET': 'SELECT',
    'IMAGE': 'UPLOAD',
    'IMAGES': 'UPLOAD',
    'ATTACHMENT': 'UPLOAD',
    'ATTACHMENTS': 'UPLOAD',
    'MOBILEPHONE': 'TEXT',
    'TABLE': 'TABLE',
    'candidateGroup': 'TREE_SELECT',
    'treeSelect': 'TREE_SELECT',
    'PERSON': 'SELECT',
    'MULTISELECT': 'SELECT',
    'DEVICESTATUS': 'SELECT'
};
