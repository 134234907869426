import { BaseEntityModel } from '@common-src/model/base-model';
import { QueryPageModel } from '@common-src/model/query-model';
import { Moment } from 'moment';
import { UserStoreModule } from '@common-src/store/modules/user';
import { dateFormat } from '@common-src/filter/date-format';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { PropertyEntityType } from '@common-src/model/enum';
import CommonService from '@common-src/service/common';
import { FormControl } from '@common-src/model/form-control';
const InspectionOptions = [
    { value: 'INSPECTION_WAIT', name: '待检' },
    { value: 'INSPECTION_WAIT_TIMEOUT', name: '超时待检' },
    { value: 'INSPECTION_DONE', name: '已检' },
    { value: 'INSPECTION_DONE_TIMEOUT', name: '超时已检' },
    { value: 'INSPECTION_TERMINATION', name: '漏检' }
];
const RepairOptions = [
    { value: 'REPAIR_WAIT', name: '待处理' },
    { value: 'REPAIR_HANDLE', name: '处理中' },
    { value: 'REPAIR_DONE', name: '已完成' },
    { value: 'REPAIR_TERMINATION', name: '已关闭' },
    { value: 'REPAIR_ON_HOLD', name: '搁置' }
];
const MaintainOptions = [
    { value: 'MAINTENANCE_WAIT', name: '待保养' },
    { value: 'MAINTENANCE_WAIT_TIMEOUT', name: '超时待保' },
    { value: 'MAINTENANCE_DONE', name: '已保养' },
    { value: 'MAINTENANCE_DONE_TIMEOUT', name: '超时已保' },
    { value: 'MAINTENANCE_TERMINATION', name: '过期关闭' }
];

export function workOrderStatusFormat(text: string) {
    const data = [...InspectionOptions, ...RepairOptions, ...MaintainOptions];
    return text ? _.find(data, r => r.value === text).name : '';
}

export class WorkOrderEntityModel extends BaseEntityModel {
    id: string = undefined;
    title: string = undefined;
    code: string = undefined;
    levelDesc: string = undefined;
    deviceId: string = undefined;
    deviceName: string = undefined;
    locationId: string = undefined;
    locationName: string = undefined;
    flowStatus: string = undefined;
    flowStatusName: string = undefined;
    createdDT: string = undefined;
    createdBy: string = undefined;
    applicant: string = undefined;
    assignee: string = undefined;

    type: string = undefined;
    workOrderTypeName: string = undefined;
    level: string = undefined;
    positionId: string = undefined;
    positionName: string = undefined;
    status: string = undefined;
    statusDesc: string = undefined;
    sponsorId: string = undefined;
    sponsorName: string = undefined;
    eventId: string = undefined;
    receiverId: string = undefined;
    receiverName: string = undefined;
    endTime: Moment = undefined;
    timeOutStatus: string = undefined;
    desc: string = undefined;
    workOrderStatus: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '编码',
                dataIndex: 'code',
                ellipsis: true,
                width: 200
            },
            {
                title: '报修对象',
                dataIndex: 'deviceName'
            },
            {
                title: '报修位置',
                dataIndex: 'locationName'
            },
            {
                title: '类型',
                dataIndex: 'workOrderTypeName'
            },
            {
                title: '受理人',
                dataIndex: 'assignee'

            },
            {
                title: '创建人',
                dataIndex: 'createdBy'
            },
            {
                title: '创建时间',
                dataIndex: 'createdDT',
                ellipsis: true,
                customRender: (text, record, index) => {
                    return dateFormat(text);
                }
            },
            {
                title: '等级',
                dataIndex: 'levelDesc',
                width: 100
            },
            {
                title: '当前节点',
                dataIndex: 'flowStatusName',
                width: 150
            },
            {
                title: '工单状态',
                dataIndex: 'workOrderStatus',
                width: 100,
                customRender: (text, record, index) => {
                    return workOrderStatusFormat(text);
                }
            },
            // {
            //     title: '超时',
            //     dataIndex: 'dueDesc',
            //     width: 100
            // },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 150
            }
        ];
    }

    toModel(json) {
        super.toModel(json);
        this.applicant = _.get(_.get(json, 'formProperties'), 'applicant');
        this.assignee = _.get(json, 'assignee') || _.get(json, 'candidateGroup');
        return this;
    }

    toService() {
        const data = super.toService();
        data.endTime = this.endTime.toDate().getTime();
        data.sponsorId = UserStoreModule.UserId;
        // delete data.code;
        delete data.levelDesc;
        delete data.positionName;
        delete data.status;
        delete data.statusDesc;
        delete data.timeOutStatus;
        delete data.sponsorName;
        delete data.receiverName;
        delete data.typeDesc;
        return data;
    }
}

export class WorkOrderQueryModel extends QueryPageModel {
    @QueryControl({
        label: '类型',
        type: QueryControlType.SELECT,
        // invisibleFunction: (model:WorkOrderQueryModel) => { return model.wOType !== JTL.CONSTANT.ALL_OPTION.value; },
        optionsPromise: CommonService.getPropertyOptions,
        optionsPromiseParam: PropertyEntityType.WORK_ORDER_TYPE_CONF,
        hasAllOption: true,
        invisibleFunction: (model: any, controlList?: Array<any>) => {
            if (controlList) {
                controlList[2].options = [];
                const name = _.find(controlList[0].options, r => r.value === model.wOType)?.name;
                if (!name) {
                    model.workOrderStatus = undefined;
                } else {
                    if (name.includes('全部')) {
                        controlList[2].options = InspectionOptions;
                        model.workOrderStatus = undefined;
                    }
                    if (name.includes('巡检')) {
                        controlList[2].options = InspectionOptions;
                        model.workOrderStatus = _.find(InspectionOptions, r => r.value === model.workOrderStatus) ? model.workOrderStatus : undefined;
                    }
                    if (name.includes('维修')) {
                        controlList[2].options = RepairOptions;
                        model.workOrderStatus = _.find(RepairOptions, r => r.value === model.workOrderStatus) ? model.workOrderStatus : undefined;
                    }
                    if (name.includes('保养')) {
                        controlList[2].options = MaintainOptions;
                        model.workOrderStatus = _.find(MaintainOptions, r => r.value === model.workOrderStatus) ? model.workOrderStatus : undefined;
                    }
                }
            }
            return false;
        }
    })
    wOType: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '状态',
        type: QueryControlType.SELECT,
        options: [
            {
                value: 'wait',
                name: '待处理'
            },
            {
                value: 'handle',
                name: '处理中'
            },
            {
                value: 'done',
                name: '已完成'
            }
        ],
        mode: 'multiple',
        style: 'line-height:40px'
    })
    status: string[] = [];

    @QueryControl({
        label: '工单状态',
        type: QueryControlType.SELECT,
        options: []
    })
    workOrderStatus: string = undefined;

    @QueryControl({
        label: '等级',
        type: QueryControlType.SELECT,
        optionsPromise: CommonService.getPropertyOptions,
        optionsPromiseParam: PropertyEntityType.WORK_ORDER_LEVEL_CONF,
        hasAllOption: true
    })
    level: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '编号',
        type: QueryControlType.TEXT
    })
    code: string = null;

    @QueryControl({
        label: '受理组',
        type: QueryControlType.TREE_SELECT,
        optionsPromise: CommonService.getOrgs,
        relationKeys: ['assignee'],
        hasAllOption: false
    })
    candidateGroup: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '受理人',
        type: QueryControlType.SELECT,
        optionsPromise: CommonService.getPersonList,
        relationPath: `${WORKORDER_BASE_REQUEST_PATH}/common/assigners/list`,
        hasAllOption: true
    })
    assignee: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '创建人',
        type: QueryControlType.SELECT,
        optionsPromise: CommonService.getPersonList,
        hasAllOption: true
    })
    createBy: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '评价',
        type: QueryControlType.SELECT,
        options: [{ value: 'toRate', name: '待评价' }, { value: 'rated', name: '已评价' }],
        hasAllOption: true
    })
    rateStatus: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '起止时间',
        type: QueryControlType.SELECT_DATE
    })
    dateRange: Array<Moment> = [];

    searchType: string = null;

    toService() {
        const data = super.toService();
        if (data.wOType) {
            data['workOrderType'] = data.wOType;
        }
        delete data.wOType;
        if (data.status) {
            data.status = data.status.join(',');
        }
        if (data.dateRange.length > 0) {
            data.createdDTStart = _.get(this.dateRange, '[0]').startOf('day');
            data.createdDTEnd = _.get(this.dateRange, '[1]').clone().add(1, 'day').startOf('day');
            delete data.dateRange;
        }

        return data;
    }
}
